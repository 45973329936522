<template>
  <v-container class="pa-0">
    <v-card class="mb-2 custom-shadow">
      <v-container>
        <v-row no-gutters>
          <v-col
            :class="$vuetify.breakpoint.xsOnly ? 'd-flex justify-center': ''"
            cols="12"
            lg="2"
            md="3"
            sm="4"
          >
            <div>
              <v-img
                :src="image"
                aspect-ratio="1"
                height="144"
                style="border-radius: 4px"
                width="144"
              >
                <template v-slot:placeholder>
                  <v-row align="center" class="fill-height ma-0" justify="center">
                    <v-progress-circular class="mx-auto my-auto" color="accent" indeterminate></v-progress-circular>
                  </v-row>
                </template>
                <template v-if="myProfile" v-slot:default>
                  <div class="d-flex justify-end align-end fill-height">
                    <v-btn
                      @click.stop="proPictureDialog = true"
                      class="ma-1"
                      color="white"
                      depressed
                      fab
                      small
                    >
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-img>
            </div>
          </v-col>
          <v-col class="space-around" cols="12" lg="6" md="5" sm="8">
            <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
            <v-row :key="index" class="pt-3" no-gutters v-for="(obj, index) in topSection">
              <v-col sm="4">
                <h5
                  :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 mb-0': ''"
                  class="font-weight-medium"
                >{{ obj.label }}</h5>
              </v-col>
              <v-col sm="8">
                <h5
                  :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1 mb-0': ''"
                  class="font-weight-medium"
                >: {{ obj.value }}</h5>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? 'mt-5': ''"
            class="flex-end"
            md="4"
            sm="12"
            v-if="!myProfile"
          >
            <v-row no-gutters>
              <v-col class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="loadingMatchingBtn"
                      @click.stop="openMatchingDialog"
                      class="mx-auto"
                      depressed
                      fab
                      v-on="on"
                    >
                      <v-icon color="pink">mdi-heart-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span>අදහස් ගලපන්න</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isFavourite"
                      :loading="loadingFavouriteBtn"
                      @click="addToFavourite"
                      class="mx-auto"
                      depressed
                      fab
                      v-on="on"
                    >
                      <v-icon color="blue">mdi-account-star</v-icon>
                    </v-btn>
                  </template>
                  <span>කැමතිම ලැයිස්තුවට එක් කරන්න</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isDisabledHorRequest"
                      :loading="loadingHorRequestBtn"
                      @click="sendRequest(false)"
                      class="mx-auto"
                      depressed
                      fab
                      v-on="on"
                    >
                      <v-icon color="green">mdi-file-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>හඳහන් ඇරයුමක් යවන්න</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="isDisabledPhotoRequest"
                      :loading="loadingPhotoRequestBtn"
                      @click="sendRequest(true)"
                      class="mx-auto"
                      depressed
                      fab
                      v-on="on"
                    >
                      <v-icon color="primary">mdi-account-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  <span>ෆොටෝ ඇරයුම යවන්න</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>

          <!-- Active when viewing own profile -->
          <v-col :class="$vuetify.breakpoint.smAndDown ? 'mt-5': 'flex-end'" v-else>
            <v-btn
              :block="$vuetify.breakpoint.smAndDown"
              @click.stop="openEditingDialog"
              color="secondary"
              depressed
            >සංස්කරණය කරන්න</v-btn>

            <v-dialog
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              v-model="editingDialog"
            >
              <v-card>
                <v-toolbar color="primary" dark>
                  <v-btn @click="editingDialog = false" dark icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>සංස්කරණය කරන්න</v-toolbar-title>
                </v-toolbar>

                <!-- Update form -->
                <v-container>
                  <v-form @submit.prevent="update" class="mt-5" lazy-validation ref="form">
                    <h4 class="mb-5">ගිණුමේ විස්තර</h4>

                    <div class="box">
                      <v-row>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-text-field dense label="මාසික ආදායම" v-model="profileData.incomeValue"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-select
                            :items="metaData.countries"
                            dense
                            item-text="name"
                            label="ජීවත්ව සිටින රට"
                            v-model="profileData.livingCountry"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-select
                            :items="metaData.brothers"
                            dense
                            item-text="value"
                            label="සහෝදරයන් සංඛ්‍යාව"
                            v-model="profileData.brothersCount"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-select
                            :items="metaData.sisters"
                            dense
                            item-text="value"
                            label="සහෝදරියන් සංඛ්‍යාව"
                            v-model="profileData.sistersCount"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-select
                            :items="metaData.countries"
                            dense
                            item-text="name"
                            label="උපන් රට"
                            v-model="profileData.birthCountry"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-text-field dense label="උපන් ස්ථානය" v-model="profileData.birthPlace"></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="3" md="4" sm="6">
                          <v-dialog
                            :return-value.sync="profileData.birthTime"
                            persistent
                            ref="dialog"
                            v-model="timeModal"
                            width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                dense
                                label="උපන් වේලාව"
                                readonly
                                v-model="profileData.birthTime"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker
                              full-width
                              v-if="timeModal"
                              v-model="profileData.birthTime"
                            >
                              <v-spacer></v-spacer>
                              <v-btn @click="timeModal = false" color="primary" text>Cancel</v-btn>
                              <v-btn
                                @click="$refs.dialog.save(profileData.birthTime)"
                                color="primary"
                                text
                              >OK</v-btn>
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </div>

                    <!-- Finder details -->
                    <h4 class="mt-8 mb-5">සොයන {{partnerText}}ග‌ේ විස්තර</h4>
                    <div class="box">
                      <v-row no-gutters>
                        <v-col>
                          <p class="mb-0">{{partnerText}}ග‌ේ ජාතිය වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="6"
                          md="3"
                          sm="4"
                          v-for="(race, index) in metaData.races"
                        >
                          <v-checkbox
                            :label="race.name"
                            :rules="[!!finderData.races.length || '']"
                            :value="race.name"
                            @change="e => setFinderValues(e, 'races')"
                            hide-details
                            v-model.lazy="finderData.races"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ ආගම වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="6"
                          md="3"
                          sm="4"
                          v-for="(religion, index) in metaData.religions"
                        >
                          <v-checkbox
                            :label="religion.name"
                            :rules="[!!finderData.religions.length || '']"
                            :value="religion.name"
                            @change="e => setFinderValues(e, 'religions')"
                            hide-details
                            v-model.lazy="finderData.religions"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ කුලය වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="6"
                          md="3"
                          sm="4"
                          v-for="(cast, index) in metaData.casts"
                        >
                          <v-checkbox
                            :label="cast.name"
                            :rules="[!!finderData.casts.length || '']"
                            :value="cast.name"
                            @change="e => setFinderValues(e, 'casts')"
                            hide-details
                            v-model="finderData.casts"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ දිස්ත්‍රික්කය වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="6"
                          md="3"
                          sm="4"
                          v-for="(district, index) in metaData.districts"
                        >
                          <v-checkbox
                            :label="district.name"
                            :rules="[!!finderData.districts.length || '']"
                            :value="district.name"
                            @change="e => setFinderValues(e, 'districts')"
                            hide-details
                            v-model="finderData.districts"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ විවාහක තත්වය වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="6"
                          md="3"
                          sm="4"
                          v-for="(marriageStatus, index) in metaData.marriageStatuses"
                        >
                          <v-checkbox
                            :label="marriageStatus.status"
                            :rules="[!!finderData.marriageStatuses.length || '']"
                            :value="marriageStatus.status"
                            @change="e => setFinderValues(e, 'marriageStatuses')"
                            hide-details
                            v-model="finderData.marriageStatuses"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ අධ්‍යාපන මට්ටම වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="12"
                          md="3"
                          sm="4"
                          v-for="(education, index) in metaData.educations"
                        >
                          <v-checkbox
                            :label="education.level"
                            :rules="[!!finderData.educations.length || '']"
                            :value="education.level"
                            @change="e => setFinderValues(e, 'educations')"
                            hide-details
                            v-model="finderData.educations"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ රැකියා අංශය වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="12"
                          md="3"
                          sm="4"
                          v-for="(job, index) in metaData.jobs"
                        >
                          <v-checkbox
                            :label="job.name"
                            :rules="[!!finderData.jobs.length || '']"
                            :value="job.name"
                            @change="e => setFinderValues(e, 'jobs')"
                            hide-details
                            v-model="finderData.jobs"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ මාසික අදායම වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          :key="index"
                          cols="12"
                          md="3"
                          sm="4"
                          v-for="(monthlyIncome, index) in metaData.monthlyIncomes"
                        >
                          <v-checkbox
                            :label="monthlyIncome.value"
                            :rules="[!!finderData.monthlyIncomes.length || '']"
                            :value="monthlyIncome.value"
                            @change="e => setFinderValues(e, 'monthlyIncomes')"
                            hide-details
                            v-model="finderData.monthlyIncomes"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row class="mt-5" no-gutters>
                        <v-col>
                          <p class="mb-0 mt-4">{{partnerText}}ග‌ේ අවම හා උපරිම වයස වියයුත්තේ</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="px-4">
                          <v-range-slider
                            :value="finderData.ageRange"
                            @change="(value) => finderData.ageRange = value"
                            class="mt-8 align-center"
                            hide-details
                            max="70"
                            min="18"
                            thumb-label="always"
                          ></v-range-slider>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row justify="end" no-gutters>
                      <v-btn
                        @click="editingDialog = false"
                        class="my-5 mr-3"
                        color="error"
                        elevation="5"
                      >අවලංගු කරන්න</v-btn>
                      <v-btn
                        :loading="loadingUpdateBtn"
                        class="my-5"
                        color="accent"
                        elevation="5"
                        type="submit"
                      >යාවත්කාලීන කරන්න</v-btn>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider></v-divider>

    <v-card class="custom-shadow">
      <v-container>
        <v-row no-gutters>
          <v-col :key="index" class="py-1" cols="12" md="6" v-for="(obj, index) in bottomSection">
            <v-row no-gutters>
              <v-col sm="5">
                <h6>{{obj.label}}</h6>
              </v-col>
              <v-col sm="7">
                <h6 class="font-weight-bold">: {{obj.value}}</h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="!myProfile">
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <v-col class="d-flex align-center" sm="5">
                  <h6 class="mb-0">දුරකථන අංකය</h6>
                  <v-btn
                    :loading="loadingMobileNumberViewBtn"
                    :x-small="$vuetify.breakpoint.xsOnly"
                    @click="requestMobileNumber"
                    class="ml-2"
                    color="secondary"
                    icon
                    v-if="!isMobileNumberShow"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="d-flex align-center" sm="7">
                  <h6 class="font-weight-bold mb-0">
                    :
                    <span v-if="isMobileNumberShow">{{mobileNumber}}</span>
                    <span v-else>xxxxxxxxxx</span>
                  </h6>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-alert
            border="right"
            class="my-2"
            dense
            dismissible
            prominent
            transition="scale-transition"
            type="info"
            v-model="phoneLimitAlert"
          >
            <router-link
              :to="{ name: 'package' }"
              style="color:inherit; text-decoration: underline;"
            >පැක‌ේජයක් ලබාගන්න</router-link>
          </v-alert>
        </div>
        <v-divider></v-divider>
        <v-row :key="index" class="mt-4" no-gutters v-for="(obj, index) in extraDetails">
          <v-col cols="12">
            <h6 class="font-weight-medium">{{obj.label}}</h6>
          </v-col>
          <v-col cols="12">
            <h6 class="font-weight-bold">{{obj.value}}</h6>
          </v-col>
        </v-row>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="pl-0">
              <h6 class="my-0">හඳහන් විස්තර</h6>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col
                  :key="index"
                  class="py-1"
                  cols="12"
                  md="6"
                  sm="12"
                  v-for="(obj, index) in horoscopeDetails"
                >
                  <v-row no-gutters>
                    <v-col sm="5">
                      <h6 class="font-weight-medium">{{obj.label}}</h6>
                    </v-col>
                    <v-col sm="7">
                      <h6 class="font-weight-bold">: {{obj.value}}</h6>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>

    <!-- Profile picture update dialog -->
    <v-dialog max-width="400" v-model="proPictureDialog">
      <v-card>
        <v-card-text class="d-flex justify-center px-0 pt-12">
          <v-image-input
            :hideActions="hideActions"
            :imageHeight="$vuetify.breakpoint.name == 'xs' ? 212 : 256"
            :imageWidth="$vuetify.breakpoint.name == 'xs' ? 212 : 256"
            class="ml-11"
            clearable
            image-format="jpeg"
            imageMinScaling="contain"
            overlayPadding="0px"
            scalingSliderColor="accent"
            v-model="profilePictureFile"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :small="$vuetify.breakpoint.xsOnly"
            @click="proPictureDialog = false"
            color="error"
            text
          >අවලංගු කරන්න</v-btn>
          <v-btn
            :disabled="!profilePictureFile"
            :loading="loadingProPictureUpdateBtn"
            :small="$vuetify.breakpoint.xsOnly"
            @click="updateProfilePicture"
            color="accent"
            text
          >යාවත්කාලීන කරන්න</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Matching dialog -->
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="matchingDialog"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn @click="matchingDialog = false" dark icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ඔබ ද‌ෙපලග‌ේ ගැලපීම</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn @click="matchingDialog = false" dark text>පිටව‌ෙන්න</v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div v-if="currentPackageId != 1">
          <v-card-text v-if="showMatchResult">
            <v-container>
              <v-row justify="center">
                <v-col>
                  <h4 class="px-12 text-center mt-2">{{partnerText}} ඔබට ගැලපීම</h4>
                  <v-divider class="mb-10 mx-12"></v-divider>
                  <h5 :key="i" class="px-12" v-for="(item, i) in proponentToUser">
                    {{item.label}}
                    <v-icon
                      class="float-right"
                      color="accent"
                      size="28"
                    >{{ item.value ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
                    <v-divider class="my-2"></v-divider>
                  </h5>
                  <h4
                    class="text-center font-weight-bold mt-6"
                    style="color: #FF5252"
                  >ගැලපීම : {{proponentToUserPercentage}}%</h4>
                </v-col>
                <v-col>
                  <h4 class="px-12 text-center mt-2">ඔබ {{partnerText}}ට ගැලපීම</h4>
                  <v-divider class="mb-10 mx-12"></v-divider>
                  <h5 :key="i" class="px-12" v-for="(item, i) in userToProponent">
                    {{item.label}}
                    <v-icon
                      class="float-right"
                      color="accent"
                      size="28"
                    >{{ item.value ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
                    <v-divider class="my-2"></v-divider>
                  </h5>
                  <h4
                    class="text-center font-weight-bold mt-6"
                    style="color: #FF5252"
                  >ගැලපීම : {{userToProponentPercentage}}%</h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <div class="center" v-else>
            <v-btn
              :loading="loadingMatchingStartBtn"
              @click="matchProfile"
              color="primary"
              depressed
              elevation="5"
              large
            >ආරම්භ කරන්න</v-btn>
          </div>
        </div>
        <div class="d-flex flex-column justify-center align-center mt-10 mx-4" v-else>
          <h5 class="mb-sm-3 mb-md-6">
            මෙම විශේෂාංගය භාවිතා කිරීමට පැකේජයක් මිලදී ගන්න
            <v-btn icon outlined style="text-decoration: none" to="/dashboard/package">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </h5>
          <v-img class="blur" contain height="400" src="../assets/images/matching-ss.jpg"></v-img>
        </div>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { ApiService } from "../services/api.service";
import { TokenService } from "../services/storage.service";
import { Helpers } from "../helpers";
import { mapMutations } from "vuex";
import { SHOW_ALERT, CHANGE_LOADING_STATE } from "../store/mutation-types";
import VImageInput from 'vuetify-image-input/a-la-carte';
import ConfirmDialog from "./ConfirmDialog";

const matchingData = {
  age: { label: "වයස", value: false },
  race: { label: "ජාතිය", value: false },
  religion: { label: "ආගම", value: false },
  cast: { label: "කුළය", value: false },
  district: { label: "දිස්ත්‍රික්කය", value: false },
  job: { label: "රැකියාව", value: false },
  monthlyIncome: { label: "මාසික ආදායම", value: false },
  marriageStatus: { label: "විවාහක තත්වය", value: false },
  education: { label: "අධ්‍යාපන මට්ටම", value: false }
};

export default {
  props: ["myProfile"],

  components: {
    VImageInput,
    ConfirmDialog
  },

  data: () => ({
    loadingFavouriteBtn: false,
    loadingHorRequestBtn: false,
    loadingPhotoRequestBtn: false,
    loadingMobileNumberViewBtn: false,
    loadingUpdateBtn: false,
    // My profile editing data
    loadingProPictureUpdateBtn: false,
    proPictureDialog: false,
    profilePictureFile: null,
    profilePictureFileURL: '',
    editingDialog: false,
    metaData: {
      countries: [],
      brothers: [],
      sisters: [],
      races: [],
      religions: [],
      casts: [],
      districts: [],
      marriageStatuses: [],
      educations: [],
      jobs: [],
      monthlyIncomes: []
    },
    timeModal: false,
    profileData: {
      livingCountry: "",
      incomeValue: "",
      brothersCount: "",
      sistersCount: "",
      birthCountry: "",
      birthPlace: "",
      birthTime: ""
    },
    finderData: {
      races: [],
      religions: [],
      casts: [],
      districts: [],
      marriageStatuses: [],
      educations: [],
      jobs: [],
      monthlyIncomes: [],
      ageRange: [18, 30]
    },
    isPhotoRequestSent: false,
    isHoroscopeRequestSent: false,
    isFavourite: false,
    matchingDialog: false,
    loadingMatchingBtn: false,
    loadingMatchingStartBtn: false,
    showMatchResult: false,
    profilePicture: "",
    topSection: {
      gender: { label: "ස්ත්‍රී/පුරුෂ", value: "" },
      age: { label: "වයස", value: "" },
      district: { label: "දිස්ත්‍රික්කය", value: "" }
    },
    bottomSection: {
      username: { label: "සාමාජික අංකය", value: "" },
      dob: { label: "උපන් දිනය", value: "" },
      livingCountry: { label: "ජීවත්ව සිටින රට", value: "" },
      province: { label: "පළාත", value: "" },
      race: { label: "ජාතිය", value: "" },
      religion: { label: "ආගම", value: "" },
      cast: { label: "කුළය", value: "" },
      marriageStatus: { label: "විවාහක තත්වය", value: "" },
      educationLevel: { label: "අධ්‍යාපන මට්ටම", value: "" },
      job: { label: "රැකියා අංශය", value: "" },
      designation: { label: "තනතුර", value: "" },
      incomeValue: { label: "මාසික ආදායම", value: "" },
      height: { label: "උස", value: "" },
      // skinColor: { label: "සමෙහි වර්ණය", value: "" },
      // bodyShape: { label: "ශරීර හැඩය", value: "" },
      // foodHabbit: { label: "ආහාර පුරුදු", value: "" },
      // liquireUsage: { label: "දුම්වැටි මත්වතුර භාවිතය", value: "" },
      brothersCount: { label: "සහෝදරයන් සංඛ්‍යාව", value: "" },
      sistersCount: { label: "සහෝදරියන් සංඛ්‍යාව", value: "" },
      // horescopeStatus: { label: "පාප කේන්දර", value: "" },
      assets: { label: "වත්කම්/දායාද", value: "" },
    },
    horoscopeDetails: {
      lagnaya: { label: "ලග්නය", value: "" },
      ganaya: { label: "ඝණය", value: "" },
      nekatha: { label: "නැකත", value: "" },
      ravi: { label: "රවි", value: "" },
      chandra: { label: "චන්ද්‍ර", value: "" },
      kuja: { label: "කුජ", value: "" },
      budha: { label: "බුධ", value: "" },
      guru: { label: "ගුරු", value: "" },
      rahu: { label: "රාහු", value: "" },
      sikuru: { label: "සිකුරු", value: "" },
      shani: { label: "ශනි", value: "" },
      kethu: { label: "කේතු", value: "" }
    },
    extraDetails: {
      otherDetails: { label: "වෙනත් විස්තර", value: "" },
    },
    mobileNumber: "",
    showMobileNumber: false,
    profPicShowStatus: 0,
    proponentToUser: JSON.parse(JSON.stringify(matchingData)), // To avoid reactiveness
    userToProponent: JSON.parse(JSON.stringify(matchingData)), // To avoid reactiveness
    proponentToUserPercentage: 0,
    userToProponentPercentage: 0,
    phoneLimitAlert: false,
    currentPackageId: 0
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchProfile();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  computed: {
    image: function () {
      if (this.myProfile) {
        return this.$store.state.profilePicture;
      } else if (this.profilePicture && (this.profPicShowStatus == 0 || this.profPicShowStatus == 1)) {
        return process.env.VUE_APP_BASE_URL + this.profilePicture;
      } else {
        if (this.topSection.gender.value == "ස්ත්‍රී") {
          return require("@/assets/images/female_user.png");
        } else if (this.topSection.gender.value == "පුරුෂ") {
          return require("@/assets/images/male_user.png");
        } else {
          return "";
        }
      }
    },
    partnerText: function () {
      if (TokenService.getToken().gen == process.env.VUE_APP_MALE_VALUE) {
        return "සහකාරිය";
      } else {
        return "සහකරු";
      }
    },
    isDisabledHorRequest: function () {
      const { birthCountry, birthPlace, birthTime } = this.profileData;
      if (this.isHoroscopeRequestSent || (birthCountry && birthPlace && birthTime)) {
        return true;
      } else {
        return false;
      }
    },
    isDisabledPhotoRequest: function () {
      if (this.isPhotoRequestSent || (this.profilePicture && this.profPicShowStatus != 2)) {
        return true;
      } else {
        return false;
      }
    },
    hideActions: function () {
      if (this.profilePictureFile) {
        return false;
      }
      return true;
    },
    isMobileNumberShow: function () {
      if (this.showMobileNumber) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch customer profile
    fetchProfile: async function () {
      let url = "";

      if (this.myProfile) {
        url = 'auth/me';
      } else {
        url = "customers/" + this.$route.params.id;
      }

      try {
        const response = await ApiService.get(url);
        const profile = response.data;

        this.profilePicture = profile.photoUrl;
        this.profPicShowStatus = profile.profPicShowStatus;
        this.isPhotoRequestSent = profile.isPhotoRequestSent;
        this.isHoroscopeRequestSent = profile.isHoroscopeRequestSent;
        this.isFavourite = profile.isFavourite;

        this.topSection.gender.value = profile.gender;
        this.topSection.district.value = profile.district;
        this.topSection.age.value = profile.age;

        this.bottomSection.username.value = profile.id;
        this.bottomSection.dob.value = profile.dob.split("T", 1)[0].replace(/..$/, "xx");
        this.bottomSection.livingCountry.value = profile.livingCountry;
        this.bottomSection.province.value = profile.province;
        this.bottomSection.race.value = profile.race;
        this.bottomSection.religion.value = profile.religion;
        this.bottomSection.cast.value = profile.cast;
        this.bottomSection.marriageStatus.value = profile.marriageStatus;
        // this.bottomSection.numberOfChildren.value = profile.numberOfChildren;
        this.bottomSection.educationLevel.value = profile.educationLevel;
        this.bottomSection.job.value = profile.job;
        this.bottomSection.designation.value = profile.designation;
        this.bottomSection.incomeValue.value = profile.incomeValue;
        this.bottomSection.height.value = profile.height;
        // this.bottomSection.skinColor.value = profile.skinColor;
        // this.bottomSection.bodyShape.value = profile.bodyShape;
        // this.bottomSection.liquireUsage.value = profile.liquireUsage;
        this.bottomSection.brothersCount.value = profile.brothersCount;
        this.bottomSection.sistersCount.value = profile.sistersCount;
        this.bottomSection.assets.value = profile.assets ? "ඇත" : "නැත";

        this.horoscopeDetails.lagnaya.value = profile.lagna;
        this.horoscopeDetails.ganaya.value = profile.ganaya;
        this.horoscopeDetails.nekatha.value = profile.nekatha;
        this.horoscopeDetails.ravi.value = profile.ravi;
        this.horoscopeDetails.chandra.value = profile.chandra;
        this.horoscopeDetails.kuja.value = profile.kuja;
        this.horoscopeDetails.budha.value = profile.budha;
        this.horoscopeDetails.guru.value = profile.guru;
        this.horoscopeDetails.rahu.value = profile.rahu;
        this.horoscopeDetails.sikuru.value = profile.sikuru;
        this.horoscopeDetails.shani.value = profile.shani;
        this.horoscopeDetails.kethu.value = profile.kethu;

        this.extraDetails.otherDetails.value = profile.otherDetails;
        this.mobileNumber = profile.mobileNumber;

        // Setting data for updates
        this.profileData.birthCountry = profile.birthCountry;
        this.profileData.birthPlace = profile.birthPlace;
        this.profileData.birthTime = profile.birthTime;
      } catch (error) {
        console.log(error);
      }
    },

    // Send photo/horoscope requests
    sendRequest: async function (isPhotoRequest) {

      if (isPhotoRequest) {
        this.loadingPhotoRequestBtn = true;
      } else {
        this.loadingHorRequestBtn = true;
      }

      try {
        const url = "customers/send-request";
        const type = isPhotoRequest ? "photo" : "horoscope";
        const data = { to: this.bottomSection.username.value, type: type };
        const response = await ApiService.post(url, data);

        if (isPhotoRequest) {
          this.isPhotoRequestSent = true;
        } else {
          this.isHoroscopeRequestSent = true;
        }

        this.showAlert({ message: "ඇරයුම යවන ලදි", type: "success" });
      } catch (error) {
        console.log(error);
      }

      if (isPhotoRequest) {
        this.loadingPhotoRequestBtn = false;
      } else {
        this.loadingHorRequestBtn = false;
      }
    },

    // Add to favourite list
    addToFavourite: async function () {
      this.loadingFavouriteBtn = true;
      try {
        const url = "customers/add-to-favourite";
        const data = { favPersonId: this.bottomSection.username.value };
        const response = await ApiService.post(url, data);
        this.isFavourite = true;
        this.showAlert({
          message: "කැමතිම ලැයිස්තුවට එකතු කරන ලදි",
          type: "success"
        });
      } catch (error) {
        console.log(error);
      }
      this.loadingFavouriteBtn = false;
    },

    // Match two profiles
    matchProfile: async function () {
      this.loadingMatchingStartBtn = true;
      try {
        const url = "customers/match";
        const params = {
          params: { customer: this.bottomSection.username.value }
        };
        const response = await ApiService.get(url, params);
        this.showMatchResult = true;

        // Proponent to user
        this.proponentToUser.age.value = response.data.proponentToUser.result.age;
        this.proponentToUser.cast.value = response.data.proponentToUser.result.cast;
        this.proponentToUser.district.value = response.data.proponentToUser.result.district;
        this.proponentToUser.education.value = response.data.proponentToUser.result.education;
        // this.proponentToUser.foodHabbit.value = response.data.proponentToUser.result.foodHabbit;
        this.proponentToUser.job.value = response.data.proponentToUser.result.job;
        // this.proponentToUser.liquireUsage.value = response.data.proponentToUser.result.liquireUsage;
        this.proponentToUser.marriageStatus.value = response.data.proponentToUser.result.marriageStatus;
        this.proponentToUser.monthlyIncome.value = response.data.proponentToUser.result.monthlyIncome;
        this.proponentToUser.race.value = response.data.proponentToUser.result.race;
        this.proponentToUser.religion.value = response.data.proponentToUser.result.religion;
        // this.proponentToUser.smokeUsage.value = response.data.proponentToUser.result.smokeUsage;
        this.proponentToUserPercentage = response.data.proponentToUser.percentage;

        // User to proponent
        this.userToProponent.age.value = response.data.userToProponent.result.age;
        this.userToProponent.cast.value = response.data.userToProponent.result.cast;
        this.userToProponent.district.value = response.data.userToProponent.result.district;
        this.userToProponent.education.value = response.data.userToProponent.result.education;
        // this.userToProponent.foodHabbit.value = response.data.userToProponent.result.foodHabbit;
        this.userToProponent.job.value = response.data.userToProponent.result.job;
        // this.userToProponent.liquireUsage.value = response.data.userToProponent.result.liquireUsage;
        this.userToProponent.marriageStatus.value = response.data.userToProponent.result.marriageStatus;
        this.userToProponent.monthlyIncome.value = response.data.userToProponent.result.monthlyIncome;
        this.userToProponent.race.value = response.data.userToProponent.result.race;
        this.userToProponent.religion.value = response.data.userToProponent.result.religion;
        // this.userToProponent.smokeUsage.value = response.data.userToProponent.result.smokeUsage;
        this.userToProponentPercentage = response.data.userToProponent.percentage;
      } catch (error) {
        console.log(error);
      }
      this.loadingMatchingStartBtn = false;
    },

    // Open profile editing dialog
    openEditingDialog: async function () {
      this.editingDialog = true;
      this.$store.commit(CHANGE_LOADING_STATE, { active: true });

      try {
        const url = "metadata";
        const response = await ApiService.get(url);
        this.metaData.races = response.data.races;
        this.metaData.religions = response.data.religions;
        this.metaData.casts = response.data.casts.splice(0, response.data.casts.length - 1);
        this.metaData.districts = response.data.districts;
        this.metaData.marriageStatuses = response.data.marriageStatuses;
        this.metaData.educations = response.data.educations;
        this.metaData.jobs = response.data.jobs;
        this.metaData.monthlyIncomes = response.data.monthlyIncomes;
        this.metaData.countries = response.data.countries;
        this.metaData.brothers = response.data.brothers;
        this.metaData.sisters = response.data.sisters;
      } catch (error) {
        console.log(error);
      }

      try {
        const url = "customers/finder-details";
        const response = await ApiService.get(url);
        this.finderData.races = response.data.finderRace.map(race => race.value);
        this.finderData.religions = response.data.finderReligion.map(religion => religion.value);
        this.finderData.casts = response.data.finderCast.map(cast => cast.value);
        this.finderData.districts = response.data.finderDistrict.map(district => district.value);
        this.finderData.marriageStatuses = response.data.finderMarriageStatus.map(marriageStatus => marriageStatus.value);
        this.finderData.educations = response.data.finderEducation.map(education => education.value);
        this.finderData.jobs = response.data.finderJob.map(job => job.value);
        this.finderData.monthlyIncomes = response.data.finderMonthlyIncome.map(monthlyIncome => monthlyIncome.value);
        this.finderData.ageRange = [response.data.finderAge.ageFrom, response.data.finderAge.ageTo];
      } catch (error) {
        console.log(error);
      }

      // Set current data
      this.profileData.livingCountry = this.bottomSection.livingCountry.value;
      this.profileData.incomeValue = this.bottomSection.incomeValue.value;
      this.profileData.brothersCount = this.bottomSection.brothersCount.value;
      this.profileData.sistersCount = this.bottomSection.sistersCount.value;

      this.$store.commit(CHANGE_LOADING_STATE, { active: false });
    },

    // Update profile
    update: async function () {
      if (this.$refs.form.validate()) {
        this.loadingUpdateBtn = true;
        try {
          const url = `customers/update/${this.bottomSection.username.value}`;
          const data = { profile: this.profileData, finder: this.finderData };
          const response = await ApiService.put(url, data);

          // Update finder data
          this.finderData.races = response.data.finderRace.map(race => race.value);
          this.finderData.religions = response.data.finderReligion.map(religion => religion.value);
          this.finderData.casts = response.data.finderCast.map(cast => cast.value);
          this.finderData.districts = response.data.finderDistrict.map(district => district.value);
          this.finderData.marriageStatuses = response.data.finderMarriageStatus.map(marriageStatus => marriageStatus.value);
          this.finderData.educations = response.data.finderEducation.map(education => education.value);
          this.finderData.jobs = response.data.finderJob.map(job => job.value);
          this.finderData.monthlyIncomes = response.data.finderMonthlyIncome.map(monthlyIncome => monthlyIncome.value);
          this.finderData.ageRange = [response.data.finderAge[0].ageFrom, response.data.finderAge[0].ageTo];

          // Update profile data
          this.bottomSection.livingCountry.value = response.data.customer.livingCountry;
          this.bottomSection.incomeValue.value = response.data.customer.incomeValue;
          this.bottomSection.brothersCount.value = response.data.customer.brothersCount;
          this.bottomSection.sistersCount.value = response.data.customer.sistersCount;

          this.showAlert({ message: "ඔබගේ ගිණුම සාර්ථකව යාවත්කාලීන කරන ලදි", type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loadingUpdateBtn = false;
        this.editingDialog = false;
      } else {
        this.showAlert({ message: "තොරතුරු නිසි ලෙස සම්පූර්ණ කරන්න", type: "error" });
      }
    },

    // Select a picture 
    selectProfilePicture: function (file) {
      if (file) {
        this.profilePictureFile = file;
        this.profilePictureFileURL = URL.createObjectURL(file);
      }
    },

    // Update profile picture
    updateProfilePicture: async function () {
      this.loadingProPictureUpdateBtn = true;
      try {
        const fd = new FormData();
        fd.append("file", Helpers.base64ToFile(this.profilePictureFile, "Profile"));

        const url = "customers/update-profile-picture";
        const response = await ApiService.post(url, fd);

        const token = await TokenService.getToken();
        token.av = await btoa(response.data.profilePicture);
        await TokenService.saveToken(token);

        await this.$store.dispatch('loadProfilePicture');
        this.image = this.$store.state.profilePicture;

        this.showAlert({ message: 'ඔබගේ පැතිකඩ පින්තූරය යාවත්කාලීන කරන ලදි', type: "success" });
      } catch (error) {
        console.log(error);
      }
      this.loadingProPictureUpdateBtn = false;
      this.proPictureDialog = false;
    },

    // Set finder values 
    setFinderValues: function (values, property) {
      if (values.includes("නොසැලකේ")) {
        this.finderData[property] = ["නොසැලකේ"];
      } else {
        this.finderData[property] = values;
      }
    },

    // Request to view the mobile number
    requestMobileNumber: async function () {
      this.loadingMobileNumberViewBtn = true;
      try {
        const url = "customers/check-phone-count";
        const params = { params: { viewedCustomerId: this.bottomSection.username.value } }
        const response = await ApiService.get(url, params);

        if (response.data.count == -1) { // If already viewed
          this.showMobileNumber = true;
        } else if (response.data.count == 0) { // If the member reached to the limit 
          this.phoneLimitAlert = true;
        } else {
          if (await this.$refs.confirm.open('දුරකථන අංකය බලන්න',
            `ඔබට තව දුරටත් නැරඹිය හැකි දුරකතන අංක ප්‍රමාණය ${response.data.count}කි. දුරකථන අංකය බලනවාද?`,
            { color: 'info', persistent: true, width: 475 })) {
            this.viewMobileNumber();
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingMobileNumberViewBtn = false;
    },

    // Save viewed mobile number's profile in the database
    viewMobileNumber: async function () {
      try {
        const url = "customers/update-phone-count";
        const data = { viewedCustomerId: this.bottomSection.username.value }
        const response = await ApiService.post(url, data);
        if (response.data.result) {
          this.showMobileNumber = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // Open matching dialog
    openMatchingDialog: async function () {
      this.loadingMatchingBtn = true;
      try {
        const url = 'auth/me';
        const response = await ApiService.get(url);
        this.currentPackageId = await response.data.currentPackageId;
        this.matchingDialog = true;
      } catch (error) {
        console.log(error);
      }
      this.loadingMatchingBtn = false;
    }
  }
};
</script>

<style>
.space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.center {
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
}
.box {
  border: 1px solid #eeeeee;
  padding: 20px;
  border-radius: 0.5em;
}
.blur .v-responsive__content {
  backdrop-filter: blur(1.5px);
}
</style>