<template>
  <v-container>
    <DashboardHeader icon="mdi-account-box" title="මගේ ගිණුම" />
    <Profile :myProfile="true" />
  </v-container>
</template>

<script>
import DashboardHeader from "../components/DashboardHeader";
import Profile from "../components/Profile";

export default {
  name: "myProfile",
  components: {
    DashboardHeader,
    Profile
  }
}
</script>

<style>
</style>